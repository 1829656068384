<template>
  <CRow class="row-section-header">
    <CButton
        class="h6 text-muted text-left btn-section-header"
        variant="ghost"
        size="sm"
        @click="$emit('toggle', section)">
      {{this.section}}
      <CIcon
          v-if="showSection"
          name="cil-caret-top"
      />
      <CIcon
          v-if="!showSection"
          name="cil-caret-bottom"
      />
    </CButton>
  </CRow>
</template>

<script>
export default {
  name: "FormDetailSection",
  props: ["showSection", "section"]
}
</script>

<style scoped>
 .btn-section-header{
   margin-bottom: 0.5rem;
   font-weight: 500;
   line-height: 1.2;
   font-size: 0.875rem;
   text-align: left !important;
   margin-top: 0;
   padding: 0;
 }
 .row-section-header{
   align-content: start;
   padding-left: 15px;
 }
</style>