<template>
  <div>
    <CRow>
      <CCol sm="12">
        <CInput
            data-sel="ipt-addr-1"
            v-model="address.addressLine1"
            v-on:change="updateItem"
            placeholder="Enter address line 1"
            description="Address line 1"
            :readonly="!allowEdit"
            @input="address.addressLine1 = $event !== '' ? $event : null"
            v-c-tooltip="{content: tooltips.addressLine1, delay: tooltipDelay}"
        />
      </CCol>
      </CRow>
      <CRow v-if="this.displayAdditionalAddressLine">
        <CCol sm="12">
          <CInput
              data-sel="ipt-addr-2"
              v-model="address.addressLine2"
              v-on:change="updateItem"
              placeholder="Enter address line 2"
              description="Address line 2"
              :readonly="!allowEdit"
              @input="address.addressLine2 = $event !== '' ? $event : null"
              v-c-tooltip="{content: tooltips.addressLine2, delay: tooltipDelay}"
          />
        </CCol>
      </CRow>
      <CRow>
        <CCol sm="4">
          <multiselect
              data-sel="slct-cntry"
              :disabled="!allowEdit"
              v-model="address.country"
              @input="updateItem"
              :options="this.countries ? this.countries.data? this.countries.data: []: []"
              :show-labels="true"
              label="name"
              track-by="name"
              :searchable="true"
              :close-on-select="true"
              :allow-empty="true"
              select-label="Select"
              deselect-label="Remove"
              placeholder="Select country"
            />
          <CRow
              v-c-tooltip="{content: tooltips.country, delay: tooltipDelay}"
          >
            <small class="form-text text-muted w-100">Country</small>
          </CRow>
        </CCol>
        <CCol sm="3">
          <multiselect
              data-sel="slct-zip"
              :disabled="!allowEdit"
              v-model="address.postalCode"
              @input="updateItem"
              :options="this.postalCodes ? postalCodes.data? postalCodes.data: []: []"
              :show-labels="true"
              label="postalCode"
              track-by="postalCode"
              :searchable="true"
              :close-on-select="true"
              :allow-empty="true"
              select-label="Select"
              deselect-label="Remove"
              placeholder="Select postal code"
              v-on:search-change="updatePostalCodeQ"
            />
          <CRow
              v-c-tooltip="{content: tooltips.postalCode, delay: tooltipDelay}"
          >
            <small class="form-text text-muted w-100">Postal code</small>
          </CRow>
        </CCol>
        <CCol sm="5">
          <CInput
              data-sel="ipt-city"
              v-model="address.city"
              v-on:change="updateItem"
              placeholder="Enter city"
              description="City"
              :readonly="!allowEdit"
              @input="address.city = $event !== '' ? $event : null"
              v-c-tooltip="{content: tooltips.city, delay: tooltipDelay}"
          />
        </CCol>
      </CRow>
  </div>
</template>

<script>
import AddressTooltips from "@/apps/partners/tooltips/address_tooltips";

import gql from 'graphql-tag';
const queryPostalCodes = gql`
query postalCodes($countryNames: [String], $q: String){
  postalCodes(countryNames: $countryNames, q: $q, order: "postalCode") {
    data {
      nid
      postalCode
    }
  }
}
`
const queryCountries = gql`
query {
  countries(order: "name") {
    data {
      nid
      name
    }
  }
}
`

export default {
  name: "AddressForm",
  props: ['addressIn', 'displayAdditionalAddressLine'],
  data() {
    return {
      tooltips: AddressTooltips,
      tooltipDelay: 500,
      countries: [],
      postalCodes: [],
      postalCodeQ: null,
    }
  },
  methods: {
    updateItem(){
      this.$emit('update-item', this.address)
    },
    updatePostalCodeQ(event){
      this.postalCodeQ = event
    }
  },
  computed: {
    address(){
      return this.addressIn? this.addressIn: {}
    },
    allowEdit: {
      get: function() {
        return this.$store.state.allowEdit
      },
      set: function (){
      }
    }
  },
  apollo: {
    countries: {
      query: queryCountries,
      error(error) {
        console.error(error);
        this.$emit("error", JSON.stringify(error));
      },
      skip() {
        return !this.allowEdit
      }
    },
    postalCodes: {
      query: queryPostalCodes,
      variables() {
        return {
          countryNames: this.address.country? [this.address.country.name]: null,
          q: this.postalCodeQ
        }
      },
      error(error) {
        console.error(error);
        this.$emit("error", JSON.stringify(error));
      },
      skip() {
        return !this.allowEdit
      }
    },
  },
  watch:{
  }
}

</script>
