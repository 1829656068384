<template>
  <div>
    <CRow class="justify-content-left switch mb-1" v-if="allowEdit">
      <CCol sm="1" class="text-left" data-sel="btn-zips-switch">
          <CSwitch
            :checked.sync="useTXTInput"
            color="info"
            size="sm"
            variant="opposite"
            labelOn="TXT"
            labelOff="PRO"
            v-c-tooltip="'Switch input method of zipcodes'"
            />
      </CCol>
    </CRow>
    <CRow v-if="!useTXTInput&&allowEdit">
      <CCol sm="3">
        <CInput
          :readonly="true"
          description="Country"
          :value="country? country.name: null"
        />
      </CCol>
      <CCol sm="3">
        <multiselect
            data-sel="slct-zips-nuts1"
            class="multiselect-nuts"
            v-model="selectedNUTS1Regions"
            :options="this.nuts1Regions? this.nuts1Regions.data? this.nuts1Regions.data.map(item => item.name): []: []"
            :multiple="true"
            :close-on-select="false"
            :allow-empty="true"
            placeholder="NUTS1 Regions"
            deselect-label="Remove region"
            v-on:search-change="updateNUTS1RegionQ"
            :loading="$apollo.queries.nuts1Regions.loading"
        />
        <CRow>
          <small class="form-text text-muted w-100">NUTS1 Regions</small>
        </CRow>
      </CCol>
      <CCol sm="3">
        <multiselect
            data-sel="slct-zips-nuts2"
            class="multiselect-nuts"
            v-model="selectedNUTS2Regions"
            :options="this.nuts2Regions? this.nuts2Regions.data? this.nuts2Regions.data.map(item => item.name): []: []"
            :multiple="true"
            :close-on-select="false"
            :allow-empty="true"
            placeholder="NUTS2 Regions"
            deselect-label="Remove region"
            v-on:search-change="updateNUTS2RegionQ"
            :loading="$apollo.queries.nuts2Regions.loading"
        />
        <CRow>
          <small class="form-text text-muted w-100">NUTS2 Regions</small>
        </CRow>
      </CCol>
      <CCol sm="3">
        <multiselect
            data-sel="slct-zips-nuts3"
            class="multiselect-nuts"
            v-model="selectedNUTS3Regions"
            :options="this.nuts3Regions? this.nuts3Regions.data? this.nuts3Regions.data.map(item => item.name): []: []"
            :multiple="true"
            :close-on-select="false"
            :allow-empty="true"
            placeholder="NUTS3 Regions"
            deselect-label="Remove region"
            v-on:search-change="updateNUTS3RegionQ"
        />
        <CRow>
          <small class="form-text text-muted w-100">NUTS3 Regions</small>
        </CRow>
      </CCol>
    </CRow>
    <CRow v-if="useTXTInput&&allowEdit">
      <CCol sm="12">
        <CTextarea
            data-sel="ipt-zips"
            class="w-100"
            placeholder="Enter zipcodes..."
            v-model="textRegionInput"
        />
        <CRow>
          <small class="form-text text-muted w-100">Zipcode text input</small>
        </CRow>
      </CCol>
    </CRow>
    <CRow v-if="allowEdit">
      <CCol sm="2" class="text-left" v-if="allowEdit">
        <CButtonGroup class="btn-grp-select mb-1">
          <CButton
              data-sel="btn-zips-apply"
              @click="updatePostalCodeSelection"
              variant="outline"
              color="info"
              size="sm"
              :disabled="this.$apollo.queries.postalCodes.loading"
          >
            Add postal codes
          </CButton>
          <CButton
              data-sel="btn-zips-clr"
              @click="clearPostalCodeSelection"
              variant="outline"
              color="danger"
              size="sm"
          >
            Clear
          </CButton>
        </CButtonGroup>
      </CCol>
    </CRow>
    <CRow>
      <CCol :sm="12">

        <CTextarea
            data-sel="slct-zips-sngl"
            class="w-100 txt-zip"
            placeholder="Enter postal codes..."
            v-model="textPostalCodes"
            :disabled="!allowEdit"
            @input="updateItem"
        />
      </CCol>
    </CRow>
  </div>
</template>

<script>
import gql from 'graphql-tag';

const queryNUTS1Regions = gql`
query nuts1Regions($countryNames: [String]) {
  nuts1Regions(countryNames: $countryNames, order: "name") {
    data{
      name
    }
  }
}
`
const queryNUTS2Regions = gql`
query nuts2Regions($countryNames: [String], $nuts1RegionNames: [String], $q: String) {
  nuts2Regions(countryNames: $countryNames, nuts1RegionNames: $nuts1RegionNames, , q: $q, order: "name") {
    data{
      name
    }
  }
}
`
const queryNUTS3Regions = gql`
query nuts3Regions($countryNames: [String], $nuts1RegionNames: [String], $nuts2RegionNames: [String], $q: String) {
  nuts3Regions(countryNames: $countryNames, nuts1RegionNames: $nuts1RegionNames, nuts2RegionNames: $nuts2RegionNames, q: $q, order: "name") {
    data{
      name
    }
  }
}
`
const queryPostalCodes = gql`
query postalCodes($countryNames: [String], $nuts1RegionNames: [String], $nuts2RegionNames: [String], $nuts3RegionNames: [String], $q: String) {
  postalCodes(countryNames: $countryNames, nuts1RegionNames: $nuts1RegionNames, nuts2RegionNames: $nuts2RegionNames, nuts3RegionNames: $nuts3RegionNames, q: $q, order: "postalCode", limit: 10000) {
    data{
      numericPostalCode
    }
  }
}
`

export default {
  name: "PostalCodeSelector",
  props: ['postalCodesIn', 'country'],
  data() {
    return {
      nuts1Regions: [],
      selectedNUTS1Regions: [],
      nuts1RegionQ: null,
      nuts2Regions: [],
      selectedNUTS2Regions: [],
      nuts2RegionQ: null,
      nuts3Regions: [],
      selectedNUTS3Regions: [],
      nuts3RegionQ: null,
      postalCodes: [],
      useTXTInput: true,
      textRegionInput: null,
      postalCodeStore: []
    }
  },
  mounted() {
    this.updateItem();
  },
  methods: {
    updateItem(){
      if(this.selectedPostalCodes){
        this.$emit('update-item', this.selectedPostalCodes)
      }
    },
    parseTextRegionInput(){
      // format CSV or newline list of postal codes into list of objects
      return this.textRegionInput.replaceAll(";", ",").replaceAll("\n", ",").split(",").map(
          item => ({numericPostalCode: item.trim()})
      );
    },
    updatePostalCodeSelection() {
      if (this.useTXTInput) {
        let parsedCodes = this.parseTextRegionInput();
        this.selectedPostalCodes = [...(this.selectedPostalCodes || []), ...parsedCodes];
      } else {
        this.selectedPostalCodes = [...(this.selectedPostalCodes || []), ...this.postalCodes.data];
      }
    },
    clearPostalCodeSelection(){
      this.selectedPostalCodes = []
    },
    updateNUTS1RegionQ(event){
      this.nuts1RegionQ = event
    },
    updateNUTS2RegionQ(event){
      this.nuts2RegionQ = event
    },
    updateNUTS3RegionQ(event){
      this.nuts3RegionQ = event
    },
    updatePostalCodeQ(event){
      this.postalCodeQ = event
    }
  },
  computed: {
    textPostalCodes: {
      get() {
        // join all postal codes into a comma-separated string
        if(this.selectedPostalCodes){
          return this.selectedPostalCodes.map(code => code.numericPostalCode).join(', ');
        } else {
          return ''
        }
      },
      set(newValue) {
        // split the new value by commas, map each item to an object and update the postalCodeStore
        if(this.selectedPostalCodes){
          this.postalCodeStore = newValue.split(',').map(code => ({numericPostalCode: code.trim()}));
        } else {
          this.postalCodeStore = []
        }
      }
    },
    selectedPostalCodes: {
      get() {
        return this.postalCodeStore
      },
      set(newPostalCodes) {
        this.postalCodeStore = newPostalCodes
      }
    },
    allowEdit: {
      get: function() {
        return this.$store.state.allowEdit
      },
      set: function (){
      }
    }
  },
  apollo: {
    nuts1Regions: {
      query: queryNUTS1Regions,
      error(error) {
        this.error = error.message;
      },
      variables () {
        return {
          countryNames: this.country? [this.country.name]: null,
          q: this.nuts1RegionQ
        }
      },
      skip() {
        return !this.allowEdit
      }
    },
    nuts2Regions: {
      query: queryNUTS2Regions,
      error(error) {
        this.error = error.message;
      },
      variables () {
        return {
          countryNames: this.country? [this.country.name]: null,
          nuts1RegionNames: this.selectedNUTS1Regions,
          q: this.nuts2RegionQ
        }
      },
      skip() {
        return !this.allowEdit
      }
    },
    nuts3Regions: {
      query: queryNUTS3Regions,
      error(error) {
        this.error = error.message;
      },
      variables () {
        return {
          countryNames: this.country? [this.country.name]: null,
          nuts1RegionNames: this.selectedNUTS1Regions,
          nuts2RegionNames: this.selectedNUTS2Regions,
          q: this.nuts3RegionQ
        }
      },
      skip() {
        return !this.allowEdit
      }
    },
    postalCodes: {
      query: queryPostalCodes,
      error(error) {
        this.error = error.message;
      },
      variables () {
        return {
          countryNames: this.country? [this.country.name]: null,
          nuts1RegionNames: this.selectedNUTS1Regions,
          nuts2RegionNames: this.selectedNUTS2Regions,
          nuts3RegionNames: this.selectedNUTS3Regions,
          q: this.postalCodeQ
        }
      },
      skip() {
        return !this.allowEdit
      }
    }
  },
  watch:{
    selectedPostalCodes(){
      this.updateItem();
    },
    postalCodesIn(newPostalCodes){
      this.postalCodeStore = newPostalCodes;
      this.updateItem();
    }
  }
}

</script>

<style scoped>
  .multiselect-nuts{
    border-width: 2pt;
    list-style-type: none;
    margin-bottom: 1pt;
    overflow: auto;
    height: 50px;
  }
  .multiselect-zip{
    border-width: 2pt;
    list-style-type: none;
    margin-bottom: 1pt;
    overflow: auto;
    height: 200px;
  }
  .multiselect--active{
    border-width: 2pt;
    list-style-type: none;
    margin-bottom: 1pt;
    overflow: visible;
    height: 200px;
  }
  .btn-grp-select{;
    padding-top: .2rem;
  }
  .txt-zip textarea{
    height: 200px;  /* Set the value to whatever minimum height you want */
  }
</style>